<template>
	<div class="page_content">
		<div class="page_detail">
			<a-divider orientation="left">打卡信息</a-divider>
			<a-descriptions :column="1">
				<a-descriptions-item label="员工姓名">
					{{ detail.username }}
				</a-descriptions-item>
				<a-descriptions-item label="员工部门">
					{{ detail.department }}
				</a-descriptions-item>
				<a-descriptions-item label="酒店地址">
					{{ detail.hotel_address||'--' }}
				</a-descriptions-item>
				<a-descriptions-item label="入住打卡时间">
					{{ detail.live_time||'--' }}
				</a-descriptions-item>
				<a-descriptions-item label="入住打卡地点">
					{{ detail.live_address ||'--'}}
				</a-descriptions-item>
                <a-descriptions-item label="打卡距离差">
                	{{ detail.live_distance ||'--'}}
                </a-descriptions-item>
                <a-descriptions-item label="入住打卡照片">
                	<LookImages v-if="detail.live_image"  :list="[detail.live_image]"></LookImages>
                    <span v-else>
                        --
                    </span>
                </a-descriptions-item>
				<a-descriptions-item label="离店打卡时间">
					{{ detail.left_time ||'--'}}
				</a-descriptions-item>
				<a-descriptions-item label="离店打卡地点">
					{{ detail.left_address||'--' }}
				</a-descriptions-item>
                <a-descriptions-item label="离店距离差">
                	{{ detail.left_distance ||'--'}}
                </a-descriptions-item>
                <a-descriptions-item label="离店打卡照片">
                <LookImages v-if="detail.left_image"  :list="[detail.left_image]"></LookImages>
                <span v-else>--</span>
                </a-descriptions-item>
			
			</a-descriptions>
			<a-divider orientation="left" v-if='detail.order'>关联订单信息</a-divider>
			<div class="li_item">
				<div class="arpproval_title">
					<span class="department_appr">订单编号</span>
					<span>状态</span>
					<span class="department_appr">酒店名称</span>
					<span class="go_see_title">住宿人数</span>
					<span class="go_see_title">房间数</span>
					<span class="">入住时间</span>
					<span class="go_see_title">操作</span>
				</div>
				<div class="arpproval_title arpproval_title1">
					<span class="department_appr">{{detail.order.order_id}}</span>
					<span>{{detail.order.status_name}}</span>
					<span class="department_appr"> {{detail.order.hotel_address}}</span>
					<span class="go_see_title">{{detail.order.person_num}}</span>
					<span class="go_see_title">{{detail.order.room_num}}</span>
					<span class="">{{detail.order.check_in_date}}</span>
					<span class="go_see" @click="go_order()">查看 </span>
				</div>
			</div>
			<div class="box_form box_form_btns">
				<a-button type="defalut" @click="$router.back()">返回</a-button>
			</div>

		</div>
	</div>
	</div>
</template>

<script>
	import LookImages from "@/components/LookImages";
	import {
		clockInfo
	} from '@/api/evection.js'
	import Avatar from "@/components/small/Avatar";
	export default {
		components: {
			LookImages,
			Avatar
		},
		data() {
			return {
				detail: {
					trip: [],
					check_in: [],
					order:{}
				}
			}
		},
		created() {
			this.get_info()
		},
		methods: {
			go_order(){
				this.$router.push("/evection/order_details?order_id=" + this.detail.order.order_id);
			},
			get_info() {
				clockInfo({
					data: {
						id: this.$route.query.id,
					}

				}).then(res => {
					this.detail = res.data.data;
				})
			},
		}
	}
</script>

<style lang="less" scoped>
	.time_line {
		max-width: 600px;
	}

	.avatar_ul {
		display: flex;
		flex-wrap: wrap;

		.avatar {
			margin: 16px 10px 0;
			width: 80px;
		}
	}

	.page_detail {
		margin: 0;
		margin-left: 5%;

	}

	.li_item {
		.title_item {
			width: 80px;
			text-align: left;
			display: inline-block;
			color: #000;
		}

		margin-bottom: 30px;
	}

	.arpproval_title {
		height: 48px;
		padding: 15px 15px;
		text-align: left;
		background: rgba(245, 245, 245, 1);

		span {
			width: 9%;
			display: inline-block;
		}

		.department_appr {
			width: 25%;
		}
	}

	.arpproval_title1 {
		height: auto;
		background: none;
		border: 1px solid rgba(245, 245, 245, 1);
	}
	.go_see_title {
			text-align: center;
	}
	.go_see {
		color: #1890ff;
		text-align: center;
	}
</style>
